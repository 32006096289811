<template>
  <div v-if="addons.length" class="">
    <h4 class="mb-0 text-primary">Extra Services</h4>
    <hr class="mt-1"/>
    <div class="row">
        <div class="col-12">
            <ul class="list">
              <template v-for="(addon, adIndex) in addons">
                  <li :key="adIndex"
                    class="list__item p-2 mb-3 shadow-sm p-md-3 d-flex align-items-center justify-content-between bg-light d-block">
                    <div>
                      <h5 class="mb-0" >{{addon.name}}</h5>
                      <p class="mb-0"> {{addon.description}}</p>
                    </div>
                    <h5 class="mb-0">${{addon.charge | money_format}}</h5>
                  </li>
              </template>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
export default {
  props:{
    addons:{
      type:Array,
      default: () => []
    }
  }
}
</script>
