<template>
  <div class="order-brief py-4">
    <div class="row">
      <div class="col-6 col-lg-3">
          <h4 class="font-weight-bold mb-0">Item</h4>
          <p>{{cart.product.name}}</p>
      </div>
      <div class="col-6 col-lg-3">
          <h4 class="font-weight-bold mb-0">Quantity</h4>
          <p>1</p>
      </div>
      <div class="col-6 col-lg-3">
          <h4 class="font-weight-bold mb-0">Duration</h4>
          <p>{{calculateDeliveryTime(order.delivery_time)}}</p>
      </div>
      <div class="col-6 col-lg-3">
          <h4 class="font-weight-bold mb-0">Amount</h4>
          <p>${{order.amount | money_format}}</p>
      </div>
    </div>
    <feature-list :cart="cart" class="mb-4" />
    <addons :addons="cart.addons" class="mb-4" />
    <requirements class="mb-4" />
    <div>
      <h4 class="mb-0 text-primary">More Description</h4>
      <hr class="mt-1"/>
      <div v-html="order.description">
        
      </div>
      <p v-if="!order.description" class="text-muted"><em>no description to show</em></p>
    </div>
  </div>
</template>

<script>
import Requirements from "./Requirements.vue"
import Addons from "./Addons.vue"
import FeatureList from './FeatureList.vue'

export default {
  components:{
    Addons,
    Requirements,
    FeatureList
  },
  computed:{
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
  },
}

</script>

